<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma" type="primary" @click="showAddModel">新增社团</el-button>
            </div>
        </div>
        <commonTable :isTree="true" treeKey="associationId" :treeProps="treeProps" :tableData="tableData" :loading="loading"
            @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
            :total="total">
            <template v-slot:table>
                <el-table-column prop="associationName" align="left" label="社团名称" show-overflow-tooltip />
                <el-table-column prop="goodPhoto" align="center" label="社团LOGO" width="120px">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.associationImg" style="width: 100px; height: 100px;margin-bottom:-10px"
                            :src="scope.row.associationImg">
                        </el-image>
                        <p v-else style="width: 100px; height: 100px;line-height: 100px;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="creditCode" align="center" label="统一信用代码" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.creditCode ? scope.row.creditCode : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="linkman" align="center" label="联系人" show-overflow-tooltip />
                <el-table-column prop="linkmanPhone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column align="center" label="社团类型" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        {{ associationType[scope.row.associationType] }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="社团等级" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        {{ associationLevel[scope.row.associationLevel] }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="默认显示" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isTop" :active-value="1" :inactive-value="0"
                            @change="setTopFun($event, scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button type="text" @click="showOptionModel(scope.row)">设置</el-button>
                        <div v-if="!scope.row.parentId">
                            <el-button type="text" @click="showAddChildrenModel(scope.row)">新增子级社团</el-button>
                        </div>

                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            @close="closeFun" :destroy-on-close="true" width="60%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-form-item label="社团LOGO" prop="associationImg" required>
                    <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="associationImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="h100p d-flex align-items-center">
                        <p>最多只能上传1张，建议上传750x750，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="父级社团" prop="industry">
                            <el-select class="w100p" v-model="ruleForm.parentId" :disabled="formType === 'update'"
                                filterable remote placeholder="请输入关键词" :remote-method="getAssociation"
                                :loading="selectLoading">
                                <el-option v-for="(row, index) in associationData" :key="index" :label="row.associationName"
                                    :value="row.associationId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="社团名称" prop="associationName">
                            <el-autocomplete class="w100p" placeholder="请输入社团名称" :fetch-suggestions="getAssociationList"
                                @select="checkedAssociation" v-model="ruleForm.associationName" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="社团简称" prop="shortName">
                            <el-input placeholder="请输入社团简称" v-model="ruleForm.shortName" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="社团产业" prop="industry">
                            <el-select class="w100p" v-model="ruleForm.industry" filterable placeholder="请选择社团产业">
                                <el-option v-for="item in industryData" :key="item.dictValue" :label="item.dictLabel"
                                    :value="item.dictValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="社团等级" prop="associationLevel">
                            <el-select class="w100p" v-model="ruleForm.associationLevel" filterable remote
                                placeholder="请选择等级">
                                <el-option v-for="(name, id) in associationLevel" :key="id" :label="name" :value="id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="社团类型" prop="associationType">
                            <el-select class="w100p" v-model="ruleForm.associationType" filterable remote
                                placeholder="请选择类型">
                                <el-option v-for="(name, id) in associationType" :key="id" :label="name"
                                    :value="Number(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="22">
                        <el-form-item label="统一信用代码" prop="creditCode">
                            <el-input placeholder="请输入统一信用代码" v-model="ruleForm.creditCode" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="法人名称" prop="legalName">
                            <el-input placeholder="请输入法人名称" v-model="ruleForm.legalName" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="法人电话" prop="legalPhone">
                            <el-input placeholder="请输入法人电话" type="number" :maxlength="11" v-model="ruleForm.legalPhone"
                                clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="法人微信" prop="legalWx">
                            <el-input placeholder="请输入法人微信" v-model="ruleForm.legalWx" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="联系人" prop="linkman">
                            <el-input placeholder="请输入联系人" v-model="ruleForm.linkman" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="联系电话" prop="linkmanPhone">
                            <el-input placeholder="请输入联系电话" :maxlength="11" type="number" v-model="ruleForm.linkmanPhone"
                                clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="联系QQ" prop="linkmanQq">
                            <el-input placeholder="请输入联系QQ" v-model="ruleForm.linkmanQq" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="联系微信" prop="linkmanWx">
                            <el-input placeholder="请输入联系微信" v-model="ruleForm.linkmanWx" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="pr20">
                        <el-form-item label="成立时间" prop="startTime">
                            <el-date-picker v-model="ruleForm.startTime" style="width:100%" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="地区" prop="areaCode">
                    <el-select class="w130" v-model="province" placeholder="省" filterable @change="getTbArea(1, $event)">
                        <el-option v-for="item in provinceData" :key="item.areaCode" :label="item.name"
                            :value="item.areaCode">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="city" placeholder="市" filterable @change="getTbArea(2, $event)">
                        <el-option v-for="item in cityData" :key="item.areaCode" :label="item.name" :value="item.areaCode">
                        </el-option>
                    </el-select>
                    <el-select class="w130" v-model="ruleForm.areaCode" placeholder="区/县" filterable>
                        <el-option v-for="item in areaData" :key="item.areaCode" :label="item.name" :value="item.areaCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-row>
                    <el-col :md="22">
                        <el-form-item label="详细地址" prop="address">
                            <el-input placeholder="请输入详细地址" v-model="ruleForm.address" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="坐标" required>
                    <div class="d-flex">
                        <el-form-item prop="lng">
                            <el-input class="w130 mr10" type="number" placeholder="地理经度" v-model="ruleForm.lng" clearable />
                        </el-form-item>
                        <el-form-item prop="lat">
                            <el-input class="w130 mr10" type="number" placeholder="地理纬度" v-model="ruleForm.lat" clearable />
                        </el-form-item>
                        <el-button type="primary" style="height:32px" @click="showMapFun">选择坐标</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="社团介绍" prop="introduce">
                    <quill-editor style="height: 100%" v-model="ruleForm.introduce" ref="myQuillEditor"
                        :options="editorOption" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择地址" :visible.sync="showMap"
            width="80%" :destroy-on-close="true">
            <map-model @lngLatChange="lngLatChange" :lng="ruleForm.lng" :lat="ruleForm.lat"></map-model>
            <!-- <div id="baidumap" style="width: 100%; height: 400px; margin-top: 10px"></div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMap = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="社团设置" :visible.sync="showOption"
            :destroy-on-close="true" width="400px" center>
            <el-form ref="optionForm" :model="optionForm" label-width="110px">
                <el-form-item label="会员默认时长">
                    <div class="flex-c-c">
                        <i class="el-icon-info" title="第一次进入社团的默认时长"></i>
                        <el-input class="suffixInput ml10" type="number" v-model="optionForm.defaultYear">
                            <template slot="append">年</template>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="是否审核入会">
                    <el-switch v-model="optionForm.auditSwitch" active-text="审核" :active-value="1" :inactive-value="0"
                        inactive-text="不用审核">
                    </el-switch>
                </el-form-item>
                <el-form-item label="入团费用">
                    <el-radio-group v-model="optionForm.isPay">
                        <el-radio-button :label="false">免费</el-radio-button>
                        <el-radio-button :label="true">付费</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showOption = false">取 消</el-button>
                <el-button type="primary" @click="submitOptionForm" :loading="loadingSumbitOption">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写社团介绍'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);
import mapModel from "@/components/common/mapModel";

import commonTable from "@/components/common/commonTable";
import { getAssociationManageList, setAssociationManage, getByDictType, getTbArea, getParentByCode, getAssociationOption, setAssociationOption } from "@/api/association";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        quillEditor,
        mapModel
    },
    data() {
        return {
            //列表设置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            treeProps: { children: 'childrenList' },
            //模态框设置
            //新增/修改设置
            loadingBtn: false,
            associationData: [],
            selectLoading: false,
            editorOption: quillConfig,
            provinceData: [],//省数据
            cityData: [],//市数据
            areaData: [],//区数据
            associationType: { 0: "社团", 1: "协会", 2: "俱乐部", 3: "商会" },//社团类型
            associationLevel: {},//社团等级
            industryData: [],//社团产业
            province: "",//省
            city: "",//市
            disabled: false,
            ruleForm: {
                companyId: this.$store.state.loginRoot.userInfo.companyId,
                associationName: "",//社团名称
                shortName: "",//社团简称
                parentId: "",//父级id
                legalName: "",//法人名称
                creditCode: "",//统一信用代码
                legalPhone: "",//法人手机
                legalWx: "",//法人微信
                linkman: "",//联系人
                linkmanPhone: "",//联系电话
                linkmanQq: "",//联系qq
                startTime: "",//成立时间
                linkmanWx: "",//联系微信
                associationImg: "",//社团logo
                introduce: "",//介绍
                areaCode: "",//地区码
                address: "",//详情地址
                lng: 0,//经度
                lat: 0,//纬度
                industry: "",//产业
                associationType: "",//类型
                associationLevel: "",//等级
            },
            associationImg: [],
            rules: {
                associationImg: [{ required: true, message: "请上传社团LOGO" }],
                associationName: [{ required: true, message: "请输入社团名称", trigger: "blur" }],
                associationType: [{ required: true, message: "请选择社团类型", trigger: "change" }],
                associationLevel: [{ required: true, message: "请选择社团等级", trigger: "change" }],
                legalName: [{ required: true, message: "请输入法人名称", trigger: "blur" }],
                legalPhone: [{ required: true, message: "请输入法人电话", trigger: "blur" }, { message: "手机号格式错误", pattern: this.$store.state.phoneReg }],
                linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
                linkmanPhone: [{ required: true, message: "请输入联系电话", trigger: "blur" }, { message: "手机号格式错误", pattern: this.$store.state.phoneReg }],
                areaCode: [{ required: true, message: "地区必须请选择到区", trigger: "change" }],
                address: [{ required: true, message: "请输入详情地址", trigger: "blur" }],
                lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
                lat: [{ required: true, message: '请选择纬度', trigger: 'blur' }],
            },
            modelTitle: "",//新增/编辑模态框标题
            formType: "",//新增/编辑
            updateId: 0,//编辑id
            map: {}, //赋值地图
            timeFun: null,
            //地图设置
            showMap: false,
            txMap: {},
            txMapSearch: {},
            txMapSuggest: {},
            //社团费用设置
            showOption: false,
            optionAssociationId: 0,
            loadingSumbitOption: false,
            optionForm: {
                auditSwitch: 1,
                defaultYear: 1,
                isPay: false
            },
        };
    },
    watch: {
        "formData.lng": function (val) {
            this.formData.lng = Number(val);
        },
        "formData.lat": function (val) {
            this.formData.lat = Number(val);
        }
    },
    computed: {
        uploadDisabled() {
            return this.ruleForm.associationImg !== "";
        },
    },
    created() {
        this.getTbArea(0);//获取编辑地区数据
        this.getAssociation();
        this.getByDictType().then(() => {
            this.getList()
        })
    },
    methods: {
        checkedAssociation(data) {
            this.setParamsData(data, 'select');
        },
        getAssociationList(str, cb) {
            let params = {
                isPage: true,
                companyId: 0,
            };
            if (str) {
                params.associationName = str;
            }
            getAssociationManageList(params).then(res => {
                let data = [];
                if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                        data.push({
                            value: row.associationName,
                            ...row
                        });
                    }
                }
                cb(data)
            }).catch(err => {
                cb([])
            })
        },
        /**@method 修改是否置顶 */
        setTopFun(isTop, row) {
            setAssociationManage({ isTop: isTop, associationId: row.associationId }).then(res => {
                this.getList()
            }).catch(err => {
                this.getList()
            })
        },
        //获取社团设置
        async showOptionModel(row) {
            this.showOption = true;
            this.optionAssociationId = row.associationId;
            let res = await getAssociationOption({ associationId: row.associationId })
            let data = res.data;
            this.optionForm = {
                auditSwitch: data.auditSwitch,
                defaultYear: data.defaultYear,
                isPay: data.isPay
            };

        },
        //提交社团设置
        submitOptionForm() {
            let params = {
                ...this.optionForm,
                associationId: this.optionAssociationId
            };
            this.loadingSumbitOption = true;
            setAssociationOption(params).then(res => {
                this.loadingSumbitOption = false;
                if (res.code === 200) {
                    this.showOption = false;
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        getAssociation(name) {
            this.selectLoading = true;
            getAssociationManageList({
                associationName: name,
                companyId: this.userInfo.companyId,
                noParent: 1,
            }).then(res => {
                this.selectLoading = false;
                this.associationData = res.data;
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1
            this.getList();
        },
        getByDictType() {
            //产业
            getByDictType({ dictType: "industry", status: 0 }).then(industryRes => {
                try {
                    this.industryData = industryRes.data.sysDictDataList;
                } catch (err) {
                    this.industryData = [];
                }
            })
            return getByDictType({
                dictType: "association_level", status: 0
            }).then(res => {
                if (res.code === 200) {
                    let associationLevel = {};
                    for (let row of res.data.sysDictDataList) {
                        associationLevel[row.dictValue] = row.dictLabel;
                    }
                    this.associationLevel = associationLevel;
                }
            })
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyId: this.userInfo.companyId,
                tree: 1,
                isPage: 1
            };
            if (this.searchData.name) {
                params.associationName = this.searchData.name
            }
            try {
                this.loading = true;
                let result = await getAssociationManageList(params)
                this.loading = false;

                const { data } = result;
                let tableData = []
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                //-console.log(error);
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        closeFun() {
            this.associationImg = [];
        },
        /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
         * @parentCode 父标签
         * @method 获取地区数据
         */
        async getTbArea(level = 0, parentCode) {
            let params = {
                level: level,
                parentCode: parentCode
            };
            if (level === 0) {
                this.cityData = [];
                this.areaData = [];
                this.province = "";//省
                this.city = "";//市
                this.ruleForm.areaCode = "";//街道
            }
            if (level === 1) {
                this.areaData = [];
                this.city = "";//市
                this.ruleForm.areaCode = "";//街道
            }
            if (level === 2) {
                this.ruleForm.areaCode = "";//街道
            }
            if (!parentCode && level > 0) {
                return;
            }
            let res = await getTbArea(params);
            if (level === 0) {
                this.provinceData = res.data;
            }
            if (level === 1) {
                this.cityData = res.data;
            }
            if (level === 2) {
                this.areaData = res.data;
            }
        },
        /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
         * @parentCode 父标签
         * @method 获取地区数据
         */
        async getAreaData(level = 0, parentCode) {
            let params = {
                level: level,
                parentCode: parentCode
            };
            let res = await getTbArea(params);
            if (level === 0) {
                this.provinceData = res.data;
            }
            if (level === 1) {
                this.cityData = res.data;
            }
            if (level === 2) {
                this.areaData = res.data;
            }
        },
        /**@method 显示地图 */
        showMapFun() {
            this.showMap = true;
        },
        lngLatChange(lnglat) {
            //-console.log(lnglat);
            this.ruleForm.lng = lnglat.lng;
            this.ruleForm.lat = lnglat.lat;
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增社团";
            this.formType = "add";
            this.ruleForm = {
                companyId: this.userInfo.companyId,
                associationName: "",//社团名称
                shortName: "",//社团简称
                parentId: "",//父级id
                legalName: "",//法人名称
                creditCode: "",//统一信用代码
                legalPhone: "",//法人手机
                legalWx: "",//法人微信
                linkman: "",//联系人
                linkmanPhone: "",//联系电话
                linkmanQq: "",//联系qq
                linkmanWx: "",//联系微信
                startTime: "",//成立时间
                associationImg: "",//社团logo
                introduce: "",//结束
                areaCode: "",//地区码
                address: "",//详情地址
                lng: 0,//经度
                lat: 0,//纬度
                industry: "",//产业
                associationType: "",//类型
                associationLevel: "",//等级
            };
            this.province = "";//省
            this.city = "";//市
            this.cityData = [];
            this.areaData = [];
            this.updateId = 0;
        },
        showAddChildrenModel(val) {
            this.showModel = true;
            this.modelTitle = "新增子级社团";
            this.formType = "add";
            this.ruleForm = {
                companyId: this.userInfo.companyId,
                associationName: "",//社团名称
                shortName: "",//社团简称
                parentId: val.associationId,//父级id
                legalName: "",//法人名称
                creditCode: "",//统一信用代码
                legalPhone: "",//法人手机
                legalWx: "",//法人微信
                linkman: "",//联系人
                linkmanPhone: "",//联系电话
                linkmanQq: "",//联系qq
                linkmanWx: "",//联系微信
                startTime: "",//成立时间
                associationImg: "",//社团logo
                introduce: "",//结束
                areaCode: "",//地区码
                address: "",//详情地址
                lng: 0,//经度
                lat: 0,//纬度
                industry: "",//产业
                associationType: "",//类型
                associationLevel: "",//等级
            };
            this.province = "";//省
            this.city = "";//市
            this.cityData = [];
            this.areaData = [];
            this.updateId = 0;
        },
        /**@method 数据预处理 */
        async setParamsData(val,type) {
            this.ruleForm = {
                companyId: val.companyId,
                associationName: val.associationName,//社团名称
                shortName: val.shortName,//社团简称
                parentId: val.parentId,//父级id
                legalName: val.legalName,//法人名称
                creditCode: val.creditCode,//统一信用代码
                legalPhone: val.legalPhone,//法人手机
                legalWx: val.legalWx,//法人微信
                linkman: val.linkman,//联系人
                linkmanPhone: val.linkmanPhone,//联系电话
                linkmanQq: val.linkmanQq,//联系qq
                linkmanWx: val.linkmanWx,//联系微信
                startTime: val.startTime ? val.startTime.slice(0, 10) : "",//成立时间
                associationImg: val.associationImg ? val.associationImg : "",//社团logo
                introduce: val.introduce,//结束
                areaCode: val.areaCode,//地区码
                address: val.address,//详情地址
                lng: val.lng,//经度
                lat: val.lat,//纬度
                industry: val.industry,//产业
                associationType: val.associationType,//类型
                associationLevel: val.associationLevel,//等级
            }
            this.associationImg = [];
            if (type == 'update') {
                if (val.areaCode) {//初始化的时候生成地区数据
                    let res = await getParentByCode({ areaCode: val.areaCode })
                    if (res.data) {
                        for (let row of res.data) {
                            switch (row.level) {
                                case 0:
                                    this.getAreaData(row.level + 1, row.areaCode);
                                    this.province = row.areaCode;
                                    break;
                                case 1:
                                    this.getAreaData(row.level + 1, row.areaCode);
                                    this.city = row.areaCode;
                                    break;
                                case 2:
                                    this.getAreaData(row.level + 1, row.areaCode);
                                    this.ruleForm.areaCode = row.areaCode;
                                    break;
                            }
                        }
                    }
                }
            }else{
                this.province ="";
                this.city = "";
                this.ruleForm.areaCode="";
                this.ruleForm.companyId="";
            }
            if (val.associationImg) {
                this.associationImg = [{ url: val.associationImg }];
            }
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        async showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑社团";
            this.formType = "update";
            this.updateId = val.associationId;
            this.setParamsData(val, 'update');
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.ruleForm.associationImg = "";
            this.associationImg = [];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.associationImg = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 更新全局社团数据 */
        async updateAssociation() {
            let res = await getAssociationManageList({
                companyId: this.userInfo.companyId,
            })//获取社团数据
            const association = sessionStorage.getItem('checkedAssociation');
            if (association) {//storage缓存有社团数据直接保存到vuex缓存中
                this.$store.commit("setCheckedAssociation", JSON.parse(association))
            }
            let associationData = res.data ? res.data : [];
            if (associationData.length > 0) {//有社团列表直接保存到vuex缓存中
                this.$store.commit("setMyAssociationData", associationData)
                if (!association) {//storage缓存没有时初始化标题
                    this.associationTitle = associationData[0].associationName;
                    sessionStorage.setItem("checkedAssociation", JSON.stringify(associationData[0]));
                    this.$store.commit("setCheckedAssociation", associationData[0])
                }
            }
        },
        /**提交内容 */
        submitForm(payload) {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (!this.ruleForm.areaCode) {
                        this.$message({
                            message: "地区请选择到镇/街道",
                            type: "warning"
                        })
                        return;
                    }
                    let params = {
                        ...this.ruleForm,
                        associationName: this.ruleForm.associationName,
                        shortName: this.ruleForm.shortName
                    };
                    let message = "";
                    if (this.formType === 'update') {
                        message = "编辑成功"
                        params.associationId = this.updateId;
                        delete params.parentId;
                    } else {
                        message = "新增成功"
                    }
                    this.loadingBtn = true;
                    setAssociationManage(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.showModel = false;
                            this.searchData = { name: "" };
                            this.updateAssociation();
                            this.getAssociation();
                            this.getList();
                        } else {
                            this.$message(res.message)
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message(err.message)
                        }
                    })
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>